import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import VimeoEmbed from "../components/vimeo-embed";

import { StaticImage } from "gatsby-plugin-image"
import { Bounce, Fade, Slide, Zoom } from "react-awesome-reveal";

import { graphql, useStaticQuery } from "gatsby"

import appStoreBadge from '../images/app-store-badge.svg';
import googlePlayBadge from '../images/google-play-badge.svg';
import imgDefault from '../images/blog/default.jpg'

const IndexPage = () => {


  const data = useStaticQuery(graphql`
  query {
    allDibPosts(limit: 2) {
      edges {
        node {
          id
          title
          summary
          slug
          publishedAt
          featuredImage
        }
      }
    },
  }`
  )

  const posts = data?.allDibPosts?.edges;

  return (
    <Layout>
      <SEO title="Home" />

      <div className="home-hero">
        <div className="home-hero_content">
          <Zoom cascade triggerOnce>
            <h1 className="home-hero_title">Bringing People Together</h1>
            <p className="home-hero_subtitle">Implementing Restorative Practices improves Communications, Communities and Companies</p>
          </Zoom>
        </div>
        <div className="home-hero_apps">
          <div className="home-hero_apps_inner">
            <p className="home-hero_apps_title">Download the Restorativ app</p>
            <ul className="home-hero_apps_links">
              <li className="mr-2">
                <a target="blank" href="https://play.google.com/store/apps/details?id=com.axeedge.restorativ&pli=1">
                  <img src={googlePlayBadge} alt='Download from Google Play' />
                </a>
              </li>
              <li>
                <a target="blank" href="https://apps.apple.com/us/app/restorativ/id1559103892">
                  <img src={appStoreBadge} alt='Download from the App Store' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="home-apps">
          <div className="home-apps__rose">
            <Bounce fraction={0.8} triggerOnce>
              <StaticImage loading={'eager'}  placeholder="none" className="home-apps__rose__img" src="../images/homepage/rose_robot.png" alt='Rose robot logo' />
            </Bounce>
            <div className="home-apps__rose__text">
              <h3 className="mb-1">Introducing Rose</h3>
              <p className="mb-2">The first AI to help support Restorative Justice, Mediation and Mentoring</p>
              <Link className="btn" to='/solutions/predictive-ai'>Learn more about Rose</Link>
            </div>
          </div>

          <div className="home-apps__tech">
            <div className="home-apps__tech__img">
              <Bounce  fraction={0.8} triggerOnce>
                <a href="https://tech.restorativ.co/" target="blank">
                  <StaticImage loading="eager" placeholder="none"  className="home-apps__tech__img" src="../images/homepage/tech-banner.png" alt='Restorativ Tech img' />
                </a>
              </Bounce>
            </div>
            <a href="https://tech.restorativ.co/" className="btn" target="blank">Learn more about our Case for Support</a>

          </div>
        </div>
      </div>

      <div className="section-primary">
        <div className="container">
          <div className="home-donate">
            <Slide cascade triggerOnce>
              <StaticImage className="home-donate_img" src="../images/about/logos/rjcouncil.png" alt='logo' />
              <div className="home-donate_content">
                <p className="home-donate_text mb-2">Restorativ will donate all profits to, and is honoured to be a strategic partner with the RJC. Together we can make a real change to rebuilding relationships!</p>
                <Link to='/partnerships' className="btn home-donate_btn mr-2">More information</Link>
                <a target="blank" className="btn home-donate_btn" href="https://restorativejustice.org.uk/donation">Donate</a>

              </div>
            </Slide>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="home-quotes">

          <div className='quote-content'>
            <div className='quote-content__img'>
              <StaticImage width={120} height={120} src="../images/quotes/glenn-millar.png" alt='' />
            </div>
            <p className='quote-content__body'>
              Restorativ makes us do what we do best - care for our community
            </p>
            <p className="quote-content__author">Glenn Millar</p>
            <p>Alternatives RJ</p>
          </div>

          <div className='quote-content'>
            <div className='quote-content__img'>
              <StaticImage width={120} height={120} src="../images/quotes/jim-mccarthy.png" alt='' />
            </div>
            <p className='quote-content__body'>
              We decided to use Restorativ because mobile technology is the way forward for the restorative justice industry</p>
            <p className="quote-content__author">Jim McCarthy</p>
            <p>CRJ Ireland</p>
          </div>

          <div className='quote-content'>
            <div className='quote-content__img'>
              <StaticImage width={120} height={120} src="../images/quotes/twyman-ghoshal.png" alt='' />
            </div>
            <p className='quote-content__body'>
              Restorativ makes life easier for anyone who wants to manage the restoration of relationships</p>
            <p className="quote-content__author">Dr Twyman-Ghoshal</p>
            <p>Brunel University</p>
          </div>

          <div className='quote-content'>
            <div className='quote-content__img'>
              <StaticImage width={120} height={120} src="../images/quotes/leanne-morgan.png" alt='' />
            </div>
            <p className='quote-content__body'>
              The Restorativ team offered a quick and efficient data transfer solution from our current provider which got us up and running at internet pace            </p>
            <p className="quote-content__author">Leanne Morgan</p>
            <p>Wales Restorative Approaches Partnership</p>
          </div>

          <div className='quote-content'>
            <div className='quote-content__img'>
              <StaticImage width={120} height={120} src="../images/quotes/julia-clark.png" alt='' />
            </div>
            <p className='quote-content__body'>
              The Restorativ team provide a really accessible and human service when we needed a bespoke case management system. Nothing was too difficult; it was great to be able to request additional specific areas tailored to our WRAP needs alongside the RJC aligned core content.
            </p>
            <p className="quote-content__author">Julia Houlston Clark</p>
            <p>Wales Restorative Approaches Partnership</p>
          </div>

          <div className='quote-content'>
            <div className='quote-content__img'>
              <StaticImage width={120} height={120} src="../images/quotes/jim-simon.png" alt='' />
            </div>
            <p className='quote-content__body'>
              Not only is Restorativ the next generation approach to RJ, it enables us to utilise important data to help evidence the many benefits of restorative justice and ultimately influence policy
            </p>
            <p className="quote-content__author">Jim Simon</p>
            <p>Restorative Justice Council</p>
          </div>
        </div>
      </div>

      <div className="home-blend">
        <div className="home-blend_top"></div>
        <div className="home-blend_mid">
          <div className="container">
            <div className="home-blend_content">
              <div>
                <StaticImage src="../images/homepage/phone-screens.png" alt='Phone app' />
              </div>
              <div>
                <h4 className="mb-4">Why Blend Technology and RJ?</h4>
                <p>The benefits of RJ are well <a target="blank" href='https://www.gov.uk/government/collections/restorative-justice-action-plan' className="text-underline text-body-color">documented</a>; it delivers results at a lower cost compared to traditional justice processes . Restorativ provides a personalized and effective solution that will meet the high standards of anyone implementing RJ: </p>
                <ul className="home-blend_list">
                  <Fade cascade triggerOnce>
                    <li>Incorporating AI and predictive technology to aid decision making</li>
                    <li>A focus on the needs of each person participating in the RJ process via profile driven mobile technology</li>
                    <li>A case management system that has been designed to supply real-time data</li>
                    <li>A device led restorative justice approach will put the benefits of RJ iin the hands of more people</li>
                  </Fade>
                </ul>
              </div>
            </div>
            <div className="home-blend_download">
              <h4>Download the Restorativ App</h4>
              <ul className="home-blend_download_apps">
                <li className="mr-md-2">
                  <a target="blank" href="https://play.google.com/store/apps/details?id=com.axeedge.restorativ&pli=1">
                    <img src={googlePlayBadge} alt='Download from Google Play' />
                  </a>
                </li>
                <li>
                  <a target="blank" href="https://apps.apple.com/us/app/restorativ/id1559103892">
                    <img src={appStoreBadge} alt='Download from the App Store' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="home-blend_bottom"></div>
      </div>
      <div className="home-process">
        <div className="content-wrapper">
          <div className="container-fluid">
            <h2 className="text-center mb-4 pb-0">The Restorativ Process</h2>
            <div className='home-video'>
              {/* <Vimeo
                video="548480240"
                responsive
                width="600"
                showByline={false}
                showPortrait={false}
                showTitle={false}
              /> */}
              <VimeoEmbed video_id="548480240" />
            </div>
          </div>
        </div>

        {
          posts && !!posts.length && (
            <div className="content-wrapper">
              <div className="container-fluid">
                <h2 className="text-center mb-4 pb-4">Restorativ News</h2>
                <div className="row my-4">
                  {posts.map(post => {
                    return (
                      <div key={post.node.id} className="col-md-6 mb-3">
                        <div className="home-post">
                          <img className='home-post__featured-image' src={(post.node.featuredImage && post.node.featuredImage) || imgDefault} />
                          <div className="home-post__body">
                            <h3 className='home-post__title'>{post.node.title}</h3>
                            <p className='home-post__summary mb-3'>{post.node.summary}</p>
                            <Link className='btn' to={"/blog/posts/" + post.node.slug}>
                              Read more
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="home-explore">Explore the Benefits of Restorativ</div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <Zoom triggerOnce>
            <h2 className="text-center mb-4 pb-4">Solutions we offer</h2>
          </Zoom>
          <div className="row home-solutions">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h3>Restorativ Practice</h3>
                  <div className="home-solutions__img">
                    <StaticImage src="../images/homepage/icon_app.png" alt='Phone app' />
                  </div>
                  <p className='mb-3'>Putting the Power of Restorative Justice in your hands</p>
                  <AnchorLink stripHash to='/solutions#solution-app' className='btn'>Find out more</AnchorLink>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h3>Restorativ Mediation</h3>
                  <div className="home-solutions__img">
                    <StaticImage src="../images/homepage/icon_central.png" alt='Phone app' />
                  </div>
                  <p className='mb-3'>Finding common ground via technology</p>
                  <AnchorLink stripHash to='/solutions#solution-central' className='btn'>Find out more</AnchorLink>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h3> Restorativ Mentoring</h3>
                  <div className="home-solutions__img">
                    <StaticImage src="../images/homepage/icon_consulting.png" alt='Phone app' />
                  </div>
                  <p className='mb-3'>Positive influence captured via our App</p>
                  <AnchorLink stripHash to='/solutions#solution-consulting' className='btn'>Find out more</AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
